<template>
  <div>
    <div class="container-top bg">
      <!-- pc -->
      <div class="pc signup-container">
        <div class="flex-center">
          <div v-for="(item,idx) in steps" :key="'step-'+idx" class="flex-align step">
            <div class="circle" :class="{'circle-select': step === idx}">{{ idx+1 }}</div>
            <div class="body2-medium" :class="{'main': step === idx, 'sub5': step !== idx}">{{ item }}</div>
          </div>
        </div>
        <div class="signup-wrapper">
          <div class="subtitle3 main" style="margin-bottom: 16px">이메일 인증</div>
          <img src="/static/img/img_email.svg" style="margin-bottom:40px">
          <div class="body2-bold primary">{{ signupValue.username }}</div>
          <div class="body2-medium sub2" style="margin-top:8px">전송된 이메일을 확인하여 가입을 완료해주세요.</div>
          <button class="button is-primary-light" style="width:100%;margin:40px 0" @click="resend">재인증 요청</button>
          <div style="text-align: left">
            <div class="body2 sub2" style="margin-bottom: 16px">이메일 인증을 받으셔야 회원가입이 완료되며, 확인 메일이 오지 않는 경우 다음과 같이 시도해주세요.</div>
            <div class="body3 sub3">
              -스팸 메일함을 확인합니다.<br>
              -재전송 요청 버튼을 통해 메일을 재전송 받으세요.<br>
              -재전송은 최대 3회까지만 가능합니다.<br>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="mobile signup-container">
        <div class="flex-center">
          <div v-for="(item,idx) in steps" :key="'step-'+idx" class="flex-align step">
            <div class="circle" :class="{'circle-select': step === idx}">{{ idx+1 }}</div>
            <div class="body2-medium" :class="{'main': step === idx, 'sub5': step !== idx}">{{ item }}</div>
          </div>
        </div>
        <div class="signup-wrapper">
          <div class="h7 main" style="margin-bottom: 16px">이메일 인증</div>
          <img src="/static/img/img_email.svg" style="margin-bottom:32px;width:140px;height:140px">
          <div class="body2-bold primary">{{ signupValue.username }}</div>
          <div class="body4 sub2" style="margin-top:8px">전송된 이메일을 확인하여 가입을 완료해주세요.</div>
          <button class="button is-primary-light body2-medium" style="height:48px;width:100%;margin:32px 0" @click="resend">재인증 요청</button>
          <div style="text-align: left">
            <div class="body4 sub2" style="margin-bottom: 12px">이메일 인증을 받으셔야 회원가입이 완료되며, 확인 메일이 오지 않는 경우 다음과 같이 시도해주세요.</div>
            <div class="body4 sub3">
              -스팸 메일함을 확인합니다.<br>
              -재전송 요청 버튼을 통해 메일을 재전송 받으세요.<br>
              -재전송은 최대 3회까지만 가능합니다.<br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SignEmail",
    created() {
      this.signupValue.username = this.$route.query.email;
    },
    data() {
      return {
        step: 1,
        steps: ['정보입력', '가입인증', '가입완료'],
        signupValue: {
          username: ''
        },
        emailSendCount: 0,
        emailSendMessage: ''
      }
    },
    methods: {
      resend() {
        if(this.emailSendCount >= 3) {
          this.toast('이메일 인증 횟수를 초과하였습니다');
          return;
        }
        this.$store.commit('setLoading', true);
        this.$axios.get('auth/user/verify/resend', {
          params: {username: this.signupValue.username}
        }).then(() => {
          this.emailSendCount++
          this.toast('인증 이메일을 재전송하였습니다');
          this.$store.commit('setLoading', false);
        }).catch(()=>{
          this.$store.commit('setLoading', false);
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .bg
    background-color $gray4
    display flex
    justify-content center
    align-items flex-start

  .signup-container
    margin-top 80px
    width 448px
    text-align center

  .signup-wrapper
    border 1px solid $gray2
    border-radius 12px
    background-color white
    padding 40px
    margin-top 24px

  .signup-label
    text-align left
    margin-top 16px

  .circle
    width 24px
    height 24px
    border-radius 100%
    line-height 24px
    text-align center
    background-color $sub5
    color white
    margin-right 4px

  .circle-select
    background-color $main

  .step
    margin-left 45px

  .step:first-child
    margin-left 0

  @media (max-width:1024px)
    .signup-container
      margin 40px 16px
    .signup-wrapper
      padding 32px 20px
    .signup-label
      font-size 14px
    .step
      margin-left 0
      width 33.33333%
      justify-content center
</style>
